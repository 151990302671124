import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConnectKitButton } from "connectkit";
import { useState } from "react";
import { useAccount, useContractRead, useWalletClient } from "wagmi";
import Container from "./Container.jsx";
import { Web3Button } from "./Web3Button";

const abi = [
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_mintAmount",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "recipient",
        "type": "address"
      }
    ],
    "name": "mint",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
];

function Mint() {
  const maxCount = 5;
  const mintFee = 444000;
  const contractAddress = "0xAEb8a891C8D9c61a7F4711927D17D68d118CF68a";
  const [count, setCount] = useState(1);

  const { address: userAddress } = useAccount();

  const { data: supplyData, isSuccess: supplyIsSuccess, refetch: supplyRefetch } = useContractRead({
    address: contractAddress,
    abi,
    functionName: 'totalSupply',
    watch: true,
  })

  const { data: balanceData, isSuccess: balanceIsSuccess, refetch: balanceRefetch } = useContractRead({
    address: contractAddress,
    abi,
    functionName: 'balanceOf',
    args: [userAddress],
    watch: true,
    enabled: !!userAddress,
  })
  const { data: walletClient } = useWalletClient();

  console.log(walletClient);

  return <div className="flex flex-col justify-center items-center gap-y-4 gap-x-6">
    <div className="flex flex-row items-center">
      <span className="text-2xl text-white">
        {supplyIsSuccess && <>
          {`${supplyData}`}/1000 NFTs already minted.
        </>}
        {balanceIsSuccess && <>
          {' '}You own {`${balanceData}`}.
        </>}
      </span>
      {/* <div class="animate-ping h-2 w-2 rounded-full bg-sky-400 opacity-75"></div> */}
    </div>
    <div className="flex flex-row gap-2">
      <button className="btn w-10 h-10"
        onClick={ev => {
          if (count > 1) setCount(count - 1);
        }}
      >
        <FontAwesomeIcon
          icon={faMinus}
          size="xl"
          className="w-12 text-white cursor-pointer select-none"
        />
      </button>
      <div className="text-white text-2xl w-8 flex flex-col items-center justify-center">
        <span>{count}</span>
      </div>
      <button className="btn w-10 h-10"
        onClick={ev => {
          if (count < maxCount) setCount(count + 1);
        }}
      >
        <FontAwesomeIcon
          icon={faPlus}
          size="xl"
          className="w-12 text-white cursor-pointer select-none"
        />
      </button>
    </div>
    <div className="flex flex-row">
      <span className="text-white">Mint price: {(count * mintFee).toLocaleString()} PLS</span>
    </div>
    <div className="flex flex-row btn-connect-container">
      {userAddress ?
        <Web3Button
          disabled={!userAddress}
          className="h-11 w-40"
          contractAddress={contractAddress}
          contractAbi={abi}
          action={async (ctx) => {
            return ctx.estimateAndSend(
              "mint",
              [count, userAddress],
              { value: `${mintFee * count * 1e18}` }
            );
          }}
          onError={(error) => {
            alert(error);
          }}
          onSuccess={() => {
            supplyRefetch();
            balanceRefetch();
          }}
        >
          Mint
        </Web3Button>
        :
        <ConnectKitButton />
      }
    </div>
  </div >
}

export default function HeroSection({ children }) {
  return (
    <div className="relative" id="home">
      <div aria-hidden="true" className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20">
      </div>
      <video 
        autoPlay 
        muted 
        loop 
        playsInline
        className="absolute top-0 left-0 w-screen h-screen object-cover z-0"
      >
        <source src="/fractal_bg.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Container className="z-20">
        <div className="relative pt-20 ml-auto">
          <div className="lg:w-2/3 w-full max-w-[350px] lg:max-w-[900px] text-center mx-auto" style={{ width: '900px' }}>
    <div className="mb-36 group p-6 sm:p-8">
        <div className="relative overflow-hidden rounded-xl">
            <div className="hidden lg:block text-[#D86F15] font-fredoka font-bold text-5xl sm:text-8xl lg:text-[120px] drop-shadow-[-10px_0_5px_rgba(0,0,0,1)] mt-40 mobile:hidden">
            Camel Clan
          </div>
          <div className="text-white font-fredoka font-bold text-8xl sm:text-9xl lg:text-[140px] drop-shadow-[-10px_0_5px_rgba(0,0,0,1)] mt-60 sm:mt-10">
    LFG!
</div>
<div className="text-white font-fredoka font-bold text-4xl sm:text-5xl lg:text-[60px] drop-shadow-[-10px_0_5px_rgba(0,0,0,1)] mt-20 sm:mt-10">
    <a href="https://t.me/CamelClan/" className="">
        Join the Camel Clan
    </a>
</div>

            <div className="hidden lg:block text-black font-fredoka font-bold text-lg sm:text-xl lg:text-[25px] mt-10">
                CA:0xc1967c5a0cedc577d9017d2465bc0b0c55eafe49
            </div>
            {/* <div className="text-black mt-8 font-fredoka text-xs sm:text-sm lg:hidden">
    CA:0xc1967c5a0cedc577d9017d2465bc0b0c55eafe49
</div> */}
                </div>
              {/* </div>  */}
            </div>
            <div className="hidden py-8 mt-16 border-y">
              {/* <div className="text-left">
                <h6 className="text-lg font-semibold text-white dark:text-white">Planet of the apes</h6> 
                <p className="mt-2 text-white">3000 unique NFTs</p>
              </div> */}
              <div className="text-left">
                {/* <h6 className="text-lg font-semibold text-white dark:text-white">Fully packed roadmap</h6>
                <p className="mt-2 ttext-white">Token and staking coming soon</p> */}
              </div>
              <div className="text-left">
                {/* <h6 className="text-lg font-semibold text-white dark:text-white">Self-propelling community</h6> */}
                {/* <p className="mt-2 text-white">Join the quest</p> */}
              </div>
            </div>
          </div>
          <div className="mt-12 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6">
            <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
              {/* <!-- <img src="./images/clients/microsoft.svg" className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" /> --> */}
            </div>
            <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
              {/* <!-- <img src="./images/clients/airbnb.svg" className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" /> --> */}
            </div>
            <div className="p-4 flex grayscale transition duration-200 hover:grayscale-0">
              {/* <!-- <img src="./images/clients/google.svg" className="h-9 w-auto m-auto" loading="lazy" alt="client logo" width="" height="" /> --> */}
            </div>
            <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
              {/* <!-- <img src="./images/clients/ge.svg" className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" /> --> */}
            </div>
            <div className="p-4 flex grayscale transition duration-200 hover:grayscale-0">
              {/* <!-- <img src="./images/clients/netflix.svg" className="h-8 w-auto m-auto" loading="lazy" alt="client logo" width="" height="" /> --> */}
            </div>
            <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
              {/* <!-- <img src="./images/clients/google-cloud.svg" className="h-12 w-auto mx-auto" loading="lazy" alt="client logo" width="" height="" /> --> */}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
